import { postRequest, getRequest } from './index'

// 提货流水分页
export const deliveryInfoPage = (data, successCallback, failureCallback) => {
  postRequest('/deliveryInfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货流水详情
export const deliveryInfoDetail = (id, successCallback, failureCallback) => {
  getRequest(`deliveryInfo/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
