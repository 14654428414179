<template>
  <!-- 提货管理 -->
  <div class="purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table :operation-button="operationButton" :item-data="itemData" :list-data="listData" :loading="loading" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import { deliveryInfoPage } from '@/http/custApi/takeDeliveryManage'
import { agentPurchaseContractGetDeliveryApplyUrl } from '@/http/custApi/purchaseContractManage'

export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      formItemArr: [
        { type: 'input', label: '代采机构', value: 'fundsCmpName', width: '120' },
        { type: 'input', label: '合同编号', value: 'contractNo' },
        { type: 'input', label: '合作企业', value: 'supplierCmpName' },
        { type: 'input', label: '仓库名称', value: 'deliveryWarehouse' },
        { type: 'select', label: '状态', value: 'deliveryStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('pick_up_goods_status') }
      ],
      itemData: [
        { label: '提货单编号', prop: 'deliveryNo', width: 150 },
        { label: '合同编号', prop: 'contractNo', width: 180 },
        { label: '合作企业', prop: 'supplierCmpName', width: 180 },
        { label: '代采机构', prop: 'fundsCmpName', width: 180 },
        { label: '交收仓库', prop: 'deliveryWarehouse', width: 80 },
        { label: '提货金额(元)', prop: 'deliveryTotalPayment', type: 'money', width: 120 },
        { label: '代采费用(元)', prop: 'agentCost', type: 'money', width: 120 },
        // { label: '提货数量', prop: 'deliveryTotalQuantity', width: 120 },
        { label: '提货数量(吨)', prop: 'deliveryTotalWeight', width: 120 },
        // { label: '实提数量', prop: 'specs', width: 120 },
        // { label: '实提数量', prop: 'deliveryWeight', width: 120 },
        // { label: '提货申请日期', prop: 'deliveryDate', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        { label: '状态', prop: 'deliveryStatus', width: 100, child: this.$store.getters.getDictionaryItem('pick_up_goods_status') }
      ],
      listData: [],
      operationButton: [
        {
          bType: 'primary',
          label: '支付',
          handleEvent: this.paymentBillTable,
          num: 7, val: 'deliveryStatus'
        },

        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0
    }
  },
  created() {
    if (this.$route.query.contractNo) this.formInline.contractNo = this.$route.query.contractNo
  },
  methods: {
    // 获取列表
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      deliveryInfoPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 详情
    viewInfoTable(row) {
      this.$router.push({ path: '/takeDeliveryManage/takeDeliveryInfo', query: { id: row.id }})
    },
    // 支付
    paymentBillTable(row) {
      const submitObj = {
        custContractId: row.contractId,
        carNum: row.deliveryCarNo,
        driverName: row.driverName,
        driverPhone: row.driverPhone,
        remark: row.remark,
        goodsReqList: [],
        settleBankId: '',
        id: row.id
      }
      agentPurchaseContractGetDeliveryApplyUrl(submitObj.id, res => {
        window.open(res.data, '_blank')
      })
    }
  }
}
</script>
